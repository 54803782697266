import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout'
import FourOhFour from '../components/FourOhFour/FourOhFour'
import config from '../../data/SiteConfig'

const FourOhFourPage = () => (
  <Layout>
    <Helmet title={config.siteTitle} />
    <FourOhFour />
  </Layout>
)

export default FourOhFourPage