import React from "react"
import styled from "styled-components"
import CategorySVG from "../CategorySVG/CategorySVG"

const FourOhFourWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
`

const FourOhFour = () => (
  <FourOhFourWrapper>
    <h1>oops! page not found.</h1>
    <div style={{}}>
      <CategorySVG style={{height: '70vh'}} />
    </div>
  </FourOhFourWrapper>
)

export default FourOhFour