import React from 'react'
import business_plan from '../../layout/svg/undraw_business_plan.svg'
import creative_experiment from '../../layout/svg/undraw_creative_experiment.svg'
import design_thinking from '../../layout/svg/undraw_design_thinking.svg'
import developer_activity from '../../layout/svg/undraw_developer_activity.svg'
import exams from '../../layout/svg/undraw_exams.svg'
import food from '../../layout/svg/undraw_environment.svg'
import experience_design from '../../layout/svg/undraw_experience_design.svg'
import undraw_functions from '../../layout/svg/undraw_functions.svg'
import goals from '../../layout/svg/undraw_goals.svg'
import good_team from '../../layout/svg/undraw_good_team.svg'
import health_fitness from '../../layout/svg/undraw_health_fitness.svg'
import investing from '../../layout/svg/undraw_investing.svg'
import marketing from '../../layout/svg/undraw_marketing.svg'
import monitor from '../../layout/svg/undraw_monitor.svg'
import multitasking from '../../layout/svg/undraw_multitasking.svg'
import not_found from '../../layout/svg/undraw_page_not_found.svg'
import personal_growth from '../../layout/svg/undraw_personal_growth.svg'
import personal_trainer from '../../layout/svg/undraw_personal_trainer.svg'
import photography from '../../layout/svg/undraw_photography.svg'
import polaroid from '../../layout/svg/undraw_polaroid.svg'
import programmer from '../../layout/svg/undraw_programmer.svg'
// import programming from '../../layout/svg/undraw_programming.svg'
import react from '../../layout/svg/undraw_react.svg'
import recording from '../../layout/svg/undraw_recording.svg'
import servers from '../../layout/svg/undraw_server_status.svg'
import site_content from '../../layout/svg/undraw_site_content.svg'
import teacher from '../../layout/svg/undraw_teacher.svg'
import version_control from '../../layout/svg/undraw_version_control.svg'


const CategorySVG = (props) => {
  let svg 
  switch(props.category){
    case 'Business':
      svg = business_plan
      break
    case 'Creativity':
      svg = creative_experiment
      break
    case 'Course Review':
      svg = creative_experiment
      break      
    case 'Design':
      svg = design_thinking
      break
    case 'Programming':
      svg = developer_activity 
      break
    case 'Learning':
      svg = exams 
      break
    // case 'Experience Design':
    case 'Simulations and Visualizations':
      svg = experience_design 
      break
    case 'Data Science':
      svg = undraw_functions
      break
    case 'Productivity':
      svg = goals 
      break
    case 'Teamwork':
      svg = good_team 
      break
    case 'Health & Fitness':
      svg = health_fitness 
      break
    case 'Cooking & Food':
      svg = food 
      break
    case 'Marketing':
      svg = marketing 
      break 
    case 'Statistics':
      svg = investing 
      break 
    case 'Solutions':
      svg = monitor 
      break 
    case 'Self Improvement':
      svg = multitasking 
      break 
    case 'Personal Development':
      svg = personal_growth 
      break 
    case 'Personal Training':
      svg = personal_trainer 
      break 
    case 'Photography':
      svg = photography 
      break 
    case 'Advanced Photography':
      svg = polaroid 
      break 
    case 'React':
      svg = react 
      break
    case 'Web Development':
      svg = programmer 
      break 
    case 'Databases':
      // svg = programming 
      svg = servers
      break 
    case 'Music':
      svg = recording 
      break 
    case 'Site Design':
    // case 'Simulations and Visualizations':
      svg = site_content 
      break 
    case 'Teaching':
      svg = teacher 
      break 
    case 'Tech':
      svg = version_control 
      break
    default:
      svg = not_found
  }

  return <img src={svg} alt="undraw svg icon" {...props} />
}

export default CategorySVG